<template>
<!--  <div style="padding: 15px; background-color: #c5c8ce">-->
<!--    <span><router-link to="/">首页</router-link></span> |-->
<!--    <span><router-link to="/?mode=login">注册/登录</router-link></span> |-->
<!--    <span><router-link to="/jipd">免费的机票单</router-link></span> |-->
<!--    <span><router-link to="/loginOut">退出登录</router-link></span>-->
<!--    <span style="float: right">{{this.$store.getters.get_userBase.nickname}} <span v-if="this.$store.getters.get_userBase.catestr !== ''">- {{this.$store.getters.get_userBase.catestr}}</span></span>-->
<!--  </div>-->

  <!-- Offcanvas area start -->
  <div class="fix">
    <div class="offcanvas__info">
      <div class="offcanvas__wrapper">
        <div class="offcanvas__content">
          <div class="offcanvas__top mb-40 d-flex justify-content-between align-items-center">
            <div class="offcanvas__logo">
              <a href="/">
                <img src="../assets/img/logo/logo.png" alt="logo not found">
              </a>
            </div>
            <div class="offcanvas__close">
              <button>
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="offcanvas__search mb-25">
            <form action="#">
              <input type="text" placeholder="What are you searching for?">
              <button type="submit" ><i class="far fa-search"></i></button>
            </form>
          </div>
          <div class="mobile-menu fix mb-40"></div>
          <div class="offcanvas__contact mt-30 mb-20">
            <h4>Contact Info</h4>
            <ul>
              <li class="d-flex align-items-center">
                <div class="offcanvas__contact-icon mr-15">
                  <i class="fal fa-map-marker-alt"></i>
                </div>
                <div class="offcanvas__contact-text">
                  <a target="_blank" href="https://www.google.com/maps/place/Dhaka/@23.7806207,90.3492859,12z/data=!3m1!4b1!4m5!3m4!1s0x3755b8b087026b81:0x8fa563bbdd5904c2!8m2!3d23.8104753!4d90.4119873">12/A, Mirnada City Tower, NYC</a>
                </div>
              </li>
              <li class="d-flex align-items-center">
                <div class="offcanvas__contact-icon mr-15">
                  <i class="far fa-phone"></i>
                </div>
                <div class="offcanvas__contact-text">
                  <a href="tel:+088889797697">+088889797697</a>
                </div>
              </li>
              <li class="d-flex align-items-center">
                <div class="offcanvas__contact-icon mr-15">
                  <i class="fal fa-envelope"></i>
                </div>
                <div class="offcanvas__contact-text">
                  <a href="tel:+012-345-6789"><span class="mailto:support@mail.com">support@mail.com</span></a>
                </div>
              </li>
            </ul>
          </div>
          <div class="offcanvas__social">
            <ul>
              <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
              <li><a href="#"><i class="fab fa-youtube"></i></a></li>
              <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="offcanvas__overlay"></div>
  <div class="offcanvas__overlay-white"></div>
  <!-- Offcanvas area start -->

  <!-- Header area start -->
  <header>
    <div id="header-sticky" class="header__area header__transparent">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-3 col-lg-2 col-6">
            <div class="header__logo">
              <a href="/">
                <img src="../assets/img/logo/logo.png" alt="logo not found">
              </a>
            </div>
          </div>
          <div class="col-xl-6 col-lg-9 d-none d-lg-block">
            <div class="menu__main-wrapper d-flex justify-content-center">
              <div class="main-menu d-none d-none d-lg-block">
                <nav id="mobile-menu">
                  <ul>
                    <li>
                      <router-link to="/">首页</router-link>
                    </li>
                    <li>
                      <a href="https://b.vsvisa.com" target="_blank">申根机酒行助手</a>
                    </li>
                    <li>
                      <router-link to="/jipd">免费的机票单</router-link>
                    </li>
                    <li>
                      <a href="javascript:void(0);">常见问题</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">联系我们</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-1 col-6">
            <div class="header__right d-flex align-items-center justify-content-end">
              <div class="header__btn d-none d-xl-block">
                <main-user ref="mainuser" ></main-user>
              </div>
              <div class="header__toggle d-lg-none">
                <a class="sidebar__active" href="javascript:void(0)">
                  <div class="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </a>
              </div>
              <div class="header__hamburger d-none d-lg-block ml-60">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- Header area end -->

</template>
<script>
import MainUser from '@/components/MainUser.vue'

export default {
  name: 'publicTit',
  components: {
    MainUser
  },
}
</script>

<style scoped>

</style>
