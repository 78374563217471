import axios from 'axios'
/**
 * 打包的axios方法，包括异常处理等
 * @param {string} url - 初始数据请求的url地址
 * @param {object} data - 提交api的数据部分
 * @param {object} conf - 配置项
 * @returns {promise} - 返回 promise
 */
export default function ckAxios(url, data = {}, conf = {}) {
  const deftConf = {
    method: 'post', // post|get
    tunel: 'normal', // pageinit|table|.. 流程预设
    msg: true, // 是否显示提示??
  }
  const curConf = { ...deftConf, ...conf }
  /* 各tunel的执行动作，区别成功与失败 ON */
  const pageinit = (respData) => {
    let rtData = false // 是否返回接口数据
    switch (true) {
      case respData.code === 200:
        rtData = true
        break
      case respData.code === 401: // 鉴权失败
        this.$router.push('/?mode=login')
        break
      case respData.code > 401: // 500 等
        this.$Notice.error({
          title: respData.msg.title,
          desc: respData.msg.desc ?? ''
        })
        break
      default: // 320 等
        this.$Notice.error({
          title: respData.msg.title,
          desc: respData.msg.desc ?? ''
        })
    }
    return rtData
  }
  const normal = (respData) => {
    let rtData = false // 是否返回接口数据
    switch (true) {
      case respData.code === 200:
        rtData = true
        break
      case respData.code === 401: // 鉴权失败
        this.$router.push('/?mode=login')
        break
      default: // 其他均走页面处理
        this.$Notice.error({
          title: respData.msg.title,
          desc: respData.msg.desc ?? ''
        })
    }
    return rtData
  }
  const fullresp = (respData) => {
    let rtData = false // 是否返回接口数据
    switch (true) {
      case respData.code === 200:
        rtData = true
        break
      case respData.code === 401: // 鉴权失败
        this.$router.push('/?mode=login')
        break
      default: // 其他均走页面处理
        this.$Notice.error({
          title: respData.msg.title,
          desc: respData.msg.desc ?? ''
        })
    }
    return rtData
  }
  /* 各tunel的执行动作，区别成功与失败 OFF */
  const tunelDefs = {
    pageinit,
    normal,
    fullresp,
  }
  const axhead = {
    headers: {
      'mtk': localStorage.getItem('mtk') ?? '',
      'Content-Type': 'application/json'
    },
    timeout: 10000
  }

  /* eslint-disable dot-notation */
  return axios[curConf.method](url, data, axhead)
    .then((resp) => { // 有接口数据
      if (tunelDefs[curConf.tunel](resp.data)) {
        // 更新本地的mtk --->
        if (resp.headers['mtk']) {
          localStorage.setItem('mtk', resp.headers['mtk'])
        }
        if (curConf.tunel === 'fullresp') {
          return Promise.resolve(resp.data)
        } else {
          return Promise.resolve(resp.data.datas)
        }
      } else {
        return Promise.reject()
      }
    })
    .catch((err) => { // 网络状态非200
      if (err && err.message) {
        this.$Notice.error({
          title: '网络异常，请稍后再试..',
          desc: err.message || ''
        })
      }
      return Promise.reject()
    })
  /* eslint-enable dot-notation */
}
