import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '@/views/Home.vue'
import Home from '@/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页'
    },
    component: Home
  },
  {
    path: '/jipd',
    name: 'jipd',
    meta: {
      title: '免费的机票单'
    },
    component: () => import(/* webpackChunkName: "jipd" */ '../views/jipd.vue')
  },
  {
    path: '/loginOut',
    name: 'loginOut',
    meta: {
      title: '退出登录'
    },
    component: () => import(/* webpackChunkName: "loginOut" */ '../components/loginOut.vue')
  },
]

// const router = createRouter({
//   routes,
//   history: createWebHistory(process.env.BASE_URL),
//   scrollBehavior() {
//     return { top: 0 }
//   }
// })
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router
