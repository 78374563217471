import { createStore } from 'vuex'

export default createStore({
  state: {
    windowView: 'Init', // (显示模式)： Init | Desktop | Mobile | Tablet
    userMenu: [{ header: 'Loading ..', hiddenOnCollapse: false }], // （用户左侧菜单）： [] *SiderMenu使用
    userBase: { nickname: '未登录', emusuid: '', catestr: '' } // (用户相关信息-登录-等级等)
  },
  getters: {
    get_windowView: (state) => state.windowView,
    get_userMenu: (state) => state.userMenu,
    get_userBase: (state) => state.userBase,
  },
  mutations: {
    upt_windowView(state, newData) {
      state.windowView = newData
    },
    upt_userMenu(state, newData) {
      state.userMenu = newData
    },
    upt_userBase(state, newData) {
      state.userBase = newData
    },
    rest_userMenu(state) {
      state.userMenu = [{ header: 'Loading ..', hiddenOnCollapse: false }]
    },
    rest_userBase(state) {
      state.userBase = { nickname: '未登录', emusuid: '', catestr: '' }
    },
  },
  actions: {
  },
  modules: {
  }
})
