<template>
  <div>
  <button v-if="this.login_tag === ''" onclick="window.location.href='https://b.vsvisa.com/#/?mode=user_login'" class="border__btn">登录 / 注册</button>
  <div v-else><span>{{ login_tag }} </span>&nbsp;<a href="https://b.vsvisa.com/#/?mode=login_out" target="_blank">注销</a></div>
  <iframe src="https://b.vsvisa.com/login_view.html" width="1" height="1" style="position: absolute;"></iframe></div>
</template>
<script >

export default {
  name: 'MainUser',
  data() {
    return {
      login_tag: ''
    }
  },
  mounted() {
    // 在父页面中监听消息
    window.addEventListener('message', (event) => {
      // 确保消息来自正确的子页面
      if (event.origin === 'https://b.vsvisa.com') {
        // 在这里处理收到的数据
        this.login_tag = event.data
      }
    })
  },
}
</script>

<style scoped lang="less">

</style>
