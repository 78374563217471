import { createApp } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import ckAxios from './components/ckAxios.js'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.less'

const app = createApp(App)
// app.config.globalProperties.$axios = axios // this.$axios.get('/api/users')
app.config.globalProperties.$ckAxios = ckAxios

// axios.defaults.headers.common.Authorization = 'Bearer your_token_here'

app.use(store)
  .use(ViewUIPlus)
  .use(router)
  .mount('#app')
