<template>
  <!-- Footer area start -->

  <footer>
    <section class="footer__border grey__bg pt-115 pb-60">
      <div class="container">
        <div class="row">
          <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
            <div class="footer__widget footer__col-1 mb-55">
              <div class="footer__title">
                <h3><router-link to="/">首页</router-link></h3>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-3 col-md-6 col-sm-6">
            <div class="footer__widget footer__col-2 mb-55">
              <div class="footer__title">
                <h3><a href="javascript:void(0);">申根机酒行助手</a></h3>
              </div>

            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div class="footer__widget footer__col-3 mb-55">
              <div class="footer__title">
                <h3><router-link to="/jipd">免费的机票单</router-link></h3>
              </div>

            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
            <div class="footer__widget mb-55">
              <div class="footer__title">
                <h3><a href="javascript:void(0);">常见问题</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="footer__copyright grey-bg">
      <div class="container">
        <div class="copyright__inner">
          <div class="copyright__logo">
            <a href="index.html"><img src="assets/img/logo/logo.png" alt="logo not found"></a>
          </div>
          <div class="copyright__text">
            <p>Copyright &copy; 2023-2024 VSVISA.COM. <a href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备2023019731号-1</a></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer area end -->

</template>
<script>

export default {
  name: 'publicFoot',

}
</script>

<style scoped>

</style>
